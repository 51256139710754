@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f8f4ef;
}

.hero-image {
  background-image: url("../public/websize/0RE02868.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 850px;
}

@media (min-width: 300px) {
  .hero-image {
    background-position: -400px 0;
  }

  .test-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE01354.jpg");
    background-size: cover;
    height: 700px;
    background-position: -70px 0;
  }

  .dresscode-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE02537.jpg");
    background-size: cover;
    height: 700px;
    background-position: -70px 0;
  }

  .timeline-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE03709.jpg");
    background-size: cover;
    height: 700px;
    background-position: -70px 0;
  }
}

@media (min-width: 450px) {
  .hero-image {
    background-position: center center;
  }

  .split-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .details-container {
      width: 500px;
    }
  }

  .test-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE01354.jpg");
    background-size: cover;
    height: 700px;
    width: 500px;
    background-position: 0;
    border-radius: 10px;
  }

  .dresscode-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE02537.jpg");
    background-size: cover;
    height: 700px;
    width: 500px;
    background-position: 0;
    border-radius: 10px;
  }

  .timeline-background {
    margin-top: 25px;
    background-image: url("../public/websize/0RE03709.jpg");
    background-size: cover;
    height: 700px;
    width: 500px;
    background-position: 0;
    border-radius: 10px;
  }
}

@media (min-width: 390px) {
  .entourage-container {
    gap: 1rem;
  }
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/websize/0RE01093.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.abhaya-libre-regular {
  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-style: normal;
}

.cormorant-400 {
  font-family: "Cormorant", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.image-stack {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
}

.image-stack__item--top {
  grid-column: 1 / span 8;
  grid-row: 1;
  padding-top: 20%;
  z-index: 1;
}

.image-stack__item--bottom {
  grid-column: 4 / -1;
  grid-row: 1;
}
